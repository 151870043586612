body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.footer-bg {
  background-color: #faf9f9;
}

.cursor-pointer {
  cursor: pointer;
}

#navbar .nav-link {
  font-size: 15px;
  padding: 0;
  margin-top: 0;
  letter-spacing: 2.4px !important;
  font-weight: 500;
  color: #0c4d89 !important;
}

#navbar .navbar-nav {
  padding-left: 3rem !important;
}

#navbar .nav-item {
  padding-left: 2rem;
}

.filterImage {
  background: url('./assets/img/bg.jpeg');
  background-repeat: repeat-x;
  background-size: auto;
  margin-top: 20px;
  padding-top: 5rem;
  padding-bottom: 5rem !important;
}

.searchContainer .btn,
.searchContainer .btn:hover {
  border-radius: 4px;
  background-color: #0c4d89;
  display: flex;
  font-size: 1.2rem;
  letter-spacing: .76px;
  color: #fff;
  font-weight: 700;
}

.searchContainer #input_category {
  font-size: 19px;
  color: #000;
  font-weight: 600;
}

.searchContainer #select_category {
  font-size: 19px;
  color: #000;
  font-weight: 600;
}

.searchContainer h3 {
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 2.3px;
  color: #000;
}

.tags {
  background-color: #fff200;
  border-radius: 5px;
  margin: 5px;
  line-height: 14px;
  padding: 7px 0;
  font-size: 13px;
  color: #0c4d89;
  text-align: center;
  font-weight: 600;
}

.caseAbstract {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8pt;
}

.publishDate {
  color: #767a7b;
  font-size: 14px;
  line-height: 16.8px;
}

.caseAuthors {
  padding-top: 0.7rem;
  font-size: 14px;
  line-height: 16.8px;
  color: #000;
  font-weight: 700;
}

.caseTitle {
  font-size: 1rem;
  line-height: 21.6px;
  color: #4aa2da;
  text-decoration: none;
  font-weight: 500;
}

.caseTitle:hover {
  font-size: 1rem;
  line-height: 21.6px;
  color: #4aa2da;
  font-weight: 500;
  text-decoration: underline;
}

.caseCategory p {
  border-radius: 5px;
  display: inline;
  background-color: #0c4d89;
  font-size: 14px;
  line-height: 16.8px;
  color: #fff;
  padding: 5px 15px;
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}

#single-article h3 {
  display: inline-flex;
  background: #0c4d89;
  padding: 4px 40px 4px 15px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

#single-article .sugg-title {
  font-size: 16px;
  line-height: 21.6px;
  color: #4aa2da;
  display: block;
  text-decoration: none;
  padding-bottom: 10px;
  font-weight: 600;
}

#single-article .sugg-title:hover {
  font-size: 16px;
  line-height: 21.6px;
  color: #4aa2da;
  display: block;
  text-decoration: underline;
  padding-bottom: 10px;
  font-weight: 600;
}

.title {
  color: #4aa2da;
}

#single-article .publisher {
  font-size: 14px;
  color: #767a7b;
}

#single-article .author {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

#related-publications {
  font-size: 14px;
  color: #0c4d89;
}

#single-item-accordion .nav-link {
  color: #c2c3c3 !important;
  font-weight: 700;
  cursor: pointer;
}

#single-item-accordion .nav-item .active {
  color: #4aa2da !important;
  font-weight: 600;
  cursor: pointer;
}

#single-item-accordion .nav-item .active:hover {
  color: #4aa2da !important;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}